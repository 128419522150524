import React from 'react';
import { Divider } from '@mui/material';


const HRField = () => {
  return (
    <>
      <Divider sx={{ borderBottomWidth: 3 }}/>
    </>
  );
};

export default HRField;

import React from 'react';
import MainMenu from './MainMenu';

export const Header = () => {
  return (
    <header className="App-header">
      <MainMenu />
    </header>
  );
};
